export const SETTINGS = {
  HOST: 'localhost',
  VOD_BASE_URL: process.env.REACT_APP_VOD_BASE_URL,
  VOD_ABR_URL: process.env.REACT_APP_VOD_ABR_URL,
}

export const STORAGE_DIR = {
  VOD: 'images/vod',
  SCHEDULE: 'images/schedule',
  PROJECT: 'images/project',
  SUBTITLES: 'subtitles',
  SPONSOR: 'images/sponsor',
  SPEAKER: 'images/speaker',
  GUIDE: 'images/guide',
  PAGE: 'images/page',
  TEMP: 'temp',
}

export const USER = {
  TYPE: {
    ADMIN: 'admin',
    LISTENER: 'listener',
    SPEAKER: 'speaker',
    SENIOR: 'senior',
  },
}

export const SESSION_PART = {
  intro: '시작',
  group1: '1그룹',
  part1: '1부',
  group2: '2그룹',
  part2: '2부',
  group3: '3그룹',
  part3: '3부',
  closing: '종료',
  break: '쉬는시간',
  span: '테이블 상단',
  header: '테이블 해더',
}
export const SESSION_ORDER = ['intro', 'group1', 'part1', 'break', 'group1', 'part2', 'closing']

export const USER_FIELD_TYPE = {
  TEXT: 'text',
  SELECT: 'select',
  CHECK: 'check',
  PHONE: 'phone_number',
  NUMBER: 'number',
  DATE: 'date',
  BOOL: 'bool',
}
export const TEXT = {
  COPYRIGHT: process.env.REACT_APP_COPYRIGHT,
  TITLE: process.env.REACT_APP_TITLE,
  DESCRIPTION: process.env.REACT_APP_DESCRIPTION,
  ADMIN_TITLE: process.env.REACT_APP_ADMIN_TITLE,
}

export const SITE = {
  ADMIN: process.env.REACT_APP_ADMIN_URL,
  PUBLIC: process.env.REACT_APP_SITE_URL,
}
