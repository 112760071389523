import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
// import { history } from 'App'
// import * as firebase from 'services/firebase'
import * as firebase from 'services/firebase'
import moment from 'moment'
import { deactivate, currentSession } from 'services/firebase'
import { rsFirebase, fireBase } from '../../firebase'
// import { USER, PATH } from 'constant'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    reorderProgram: firebase.reorderList,
  },
}

export function* DELETE({ payload }) {
  const { id } = payload
  yield put({
    type: 'session/SET_STATE',
    payload: {
      loading: true,
    },
  })
  yield call(rsFirebase.firestore.deleteDocument, `sessions/${id}`)

  notification.success({
    message: '삭제 완료.',
    description: '세션이 삭제되었습니다.',
    duration: 1,
  })
  yield put({
    type: 'session/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* ADD({ payload }) {
  const {
    order,
    startAt,
    endAt,
    subject,
    description,
    streamingUrl,
    part,
    speaker,
    belongs,
    optionContents,
    timeExists,
    scheduleId,
    vodLink,
  } = payload

  yield put({
    type: 'session/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = {
    order,
    startAt,
    endAt,
    subject: subject || null,
    description: description || null,
    streamingUrl: streamingUrl || null,
    active: false,
    qnaEnabled: false,
    part: part || null,
    speaker: speaker || null,
    optionContents: optionContents || null,
    belongs: belongs || null,
    timeExists: timeExists || false,
    vodLink: vodLink || null,
    video: 'streaming',
    scheduleId,
  }

  yield call(rsFirebase.firestore.addDocument, 'sessions', data)
  notification.success({
    message: '등록 완료.',
    description: '세션이 등록되었습니다.',
    duration: 1,
  })
  yield put({
    type: 'session/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE({ payload }) {
  console.log(payload)

  const {
    id,
    startAt,
    endAt,
    subject,
    description,
    streamingUrl,
    part,
    speaker,
    optionContents,
    belongs,
    timeExists,
    vodLink,
  } = payload

  yield put({
    type: 'session/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = {
    startAt,
    endAt,
    subject: subject || null,
    description: description || null,
    streamingUrl: streamingUrl || null,
    part: part || null,
    speaker: speaker || null,
    optionContents: optionContents || null,
    belongs: belongs || null,
    timeExists: timeExists || false,
    vodLink: vodLink || null,
  }

  yield call(rsFirebase.firestore.setDocument, `sessions/${id}`, data, { merge: true })

  notification.success({
    message: '수정 완료.',
    description: '세션이 수정되었습니다.',
    duration: 1,
  })
  yield put({
    type: 'session/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_LIST({ payload }) {
  yield put({
    type: 'session/SET_STATE',
    payload: {
      loading: true,
    },
  })
  console.log(payload)
  yield put({
    type: 'session/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_DATA({ payload }) {
  yield put({
    type: 'session/SET_STATE',
    payload: {
      loading: true,
    },
  })
  console.log(payload)
  yield put({
    type: 'session/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* ACTIVATE({ payload }) {
  yield put({
    type: 'session/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const active = !payload.active
  yield call(deactivate)
  yield call(
    rsFirebase.firestore.setDocument,
    `sessions/${payload.id}`,
    { active: active },
    { merge: true },
  )

  yield put({
    type: 'session/SET_STATE',
    payload: {
      loading: false,
    },
  })
}
export function* ENABLE_QNA({ payload }) {
  // console.log(payload);

  yield put({
    type: 'session/SET_STATE',
    payload: {
      loading: true,
    },
  })
  console.log(payload.qnaEnabled)

  const active = !payload.qnaEnabled
  console.log('active', active)
  yield call(
    rsFirebase.firestore.setDocument,
    `sessions/${payload.id}`,
    { qnaEnabled: active },
    { merge: true },
  )
}

export function* CURRENT() {
  yield put({
    type: 'session/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const session = yield call(currentSession)
  // console.log('saga CURRENT', session);

  yield put({
    type: 'session/SET_STATE',
    payload: session,
  })
}

export function* REGIST_QUESTION({ payload }) {
  // console.log(payload)
  yield put({
    type: 'session/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const now = moment()
  console.log(payload.user)
  const uid = now.format('YYYYMMDD-HHmmss-SSS')
  const data = {
    id: uid,
    sessionId: payload.session.id,
    question: payload.question,
    user: payload.user,
    deleted: false,
    highlight: false,
    createdTimestamp: fireBase.firestore.Timestamp.now(),
    createdAt: now.format('YYYY/MM/DD HH:mm:ss'),
  }
  // console.log(data);
  yield call(
    rsFirebase.database.update,
    `questions/${data.sessionId}/${data.user.id}/${uid}/`,
    data,
  )

  yield put({
    type: 'session/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* HIGHLIGHT_QUESTION({ payload }) {
  console.log(payload)
  yield put({
    type: 'session/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const highlighted = !payload.highlight
  const data = {
    highlight: highlighted,
  }
  console.log(data)
  console.log(`questions/${payload.sessionId}/${payload.user.id}/${payload.id}/`)
  fireBase
    .database()
    .ref(`questions/${payload.sessionId}/${payload.user.id}/`)
    .child(payload.id)
    .update(data)
  // const result = yield call(
  //   rsFirebase.database.patch,
  //   `questions/${data.sessionId}/${payload.user.id}/${payload.id}`,
  //   { highlight: highlighted },
  // )

  // console.log(result)
  yield put({
    type: 'session/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* ORDER({ payload }) {
  const { authProvider: authProviderName } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProviderName].reorderProgram, payload, 'sessions')
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD, ADD),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.GET_LIST, GET_LIST),
    takeEvery(actions.GET_DATA, GET_DATA),
    takeEvery(actions.ENABLE_QNA, ENABLE_QNA),
    takeEvery(actions.ACTIVATE, ACTIVATE),
    takeEvery(actions.CURRENT, CURRENT),
    takeEvery(actions.REGIST_QUESTION, REGIST_QUESTION),
    takeEvery(actions.HIGHLIGHT_QUESTION, HIGHLIGHT_QUESTION),
    takeEvery(actions.ORDER, ORDER),
  ])
}
