import { all, takeEvery, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
// import { history } from 'App'
// import * as firebase from 'services/firebase'
import * as firebase from 'services/firebase'
import { rsFirebase } from '../../firebase'
// import { USER, PATH } from 'constant'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    reorderList: firebase.reorderList,
  },
}

export function* ADD({ payload }) {
  const data = {
    ...payload,
    // order: 999999,
  }
  console.log(data)

  yield call(rsFirebase.firestore.addDocument, 'tickets', data)
  notification.success({
    message: '등록 완료.',
    description: '티켓이 등록되었습니다.',
    duration: 1,
  })
}

export function* UPDATE({ payload }) {
  console.log(payload)
  const examId = payload.id
  const data = {
    title: payload.title,
    price: payload.price,
    isReceiptRequired: payload.isReceiptRequired,
  }

  yield call(rsFirebase.firestore.setDocument, `tickets/${examId}`, data, { merge: true })

  notification.success({
    message: '저장 완료.',
    description: '티켓 정보가 저장되었습니다.',
    duration: 1,
  })
}

export function* DELETE({ payload }) {
  yield call(rsFirebase.firestore.deleteDocument, `tickets/${payload.id}`)

  notification.success({
    message: '삭제 완료.',
    description: '티켓이 삭제되었습니다.',
    duration: 1,
  })
}

export function* ORDER({ payload }) {
  const { authProvider: authProviderName } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProviderName].reorderList, payload, 'tickets')
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD, ADD),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
  ])
}
