import { all, takeEvery, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
// import { history } from 'App'
// import * as firebase from 'services/firebase'
import * as firebase from 'services/firebase'
import { rsFirebase } from '../../firebase'
// import { USER, PATH } from 'constant'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    reorderList: firebase.reorderList,
    updateUser: firebase.updateUser,
    toggleSurveyLive: firebase.toggleSurveyLive,
  },
}

export function* DELETE({ payload }) {
  yield call(rsFirebase.firestore.deleteDocument, `survey/${payload.id}`)

  notification.success({
    message: '삭제 완료.',
    description: '설문조사 질문 항목이 삭제되었습니다.',
    duration: 1,
  })
}

export function* ADD({ payload }) {
  const data = {
    ...payload,
    // order: 999999,
  }
  console.log(data)

  yield call(rsFirebase.firestore.addDocument, 'survey', data)
  notification.success({
    message: '등록 완료.',
    description: '설문 항목이 등록되었습니다.',
    duration: 1,
  })
}

export function* UPDATE({ payload }) {
  console.log(payload)

  const surveyId = payload.id
  const data = {
    title: payload.title,
    type: payload.type,
    surveyId: payload.surveyId,
    indent: payload.indent || false,
  }
  if (payload.answers && payload.answers.length > 0) {
    data.answers = payload.answers
  }
  yield call(rsFirebase.firestore.setDocument, `survey/${surveyId}`, data, { merge: true })

  notification.success({
    message: '수정 완료.',
    description: '설문이 수정되었습니다.',
    duration: 1,
  })
}

export function* UPDATE_LIST({ payload }) {
  console.log(payload)
  const surveyId = payload.id
  const data = {
    title: payload.title,
    header: payload.header,
    footer: payload.footer,
    open: payload.open,
    live: payload.live,
  }

  yield call(rsFirebase.firestore.setDocument, `surveyList/${surveyId}`, data, { merge: true })

  notification.success({
    message: '수정 완료.',
    description: '설문이 수정되었습니다.',
    duration: 1,
  })
}

export function* DELETE_LIST({ payload }) {
  yield call(rsFirebase.firestore.deleteDocument, `surveyList/${payload.id}`)

  notification.success({
    message: '삭제 완료.',
    description: '설문조사가 삭제되었습니다.',
    duration: 1,
  })
}

export function* TOGGLE_OPEN({ payload }) {
  const surveyId = payload.id
  const data = {
    open: !payload.open,
  }

  yield call(rsFirebase.firestore.setDocument, `surveyList/${surveyId}`, data, { merge: true })

  notification.success({
    message: '수정 완료.',
    description: '설문이 수정되었습니다.',
    duration: 1,
  })
}

export function* ORDER({ payload }) {
  const { authProvider: authProviderName } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProviderName].reorderList, payload, 'survey')
}

export function* TOGGLE_LIVE({ payload }) {
  const { authProvider: authProviderName } = yield select(state => state.settings)
  yield call(
    mapAuthProviders[authProviderName].toggleSurveyLive,
    payload.surveyId,
    payload.surveyItem,
  )
}

export function* DELETE_REPORT({ payload }) {
  const { id } = payload
  yield call(rsFirebase.firestore.deleteDocument, `surveyResult/${id}`)

  const { authProvider: authProviderName } = yield select(state => state.settings)
  const userPayload = {
    id: payload.user.id,
    values: {
      surveyComplete: false,
    },
  }
  yield call(mapAuthProviders[authProviderName].updateUser, userPayload)

  notification.success({
    message: '삭제 완료.',
    description: '설문조사가 삭제되었습니다.',
    duration: 1,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD, ADD),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.UPDATE_LIST, UPDATE_LIST),
    takeEvery(actions.DELETE_LIST, DELETE_LIST),
    takeEvery(actions.TOGGLE_LIVE, TOGGLE_LIVE),
    takeEvery(actions.ORDER, ORDER),
    takeEvery(actions.DELETE_REPORT, DELETE_REPORT),
    takeEvery(actions.TOGGLE_OPEN, TOGGLE_OPEN),
  ])
}
