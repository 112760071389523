// eslint-disable-next-line no-unused-vars
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import * as firebase from 'services/firebase'
import { rsFirebase } from '../../firebase'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    reorderList: firebase.reorderList,
    getUserProfileField: firebase.getUserProfileField,
  },
}

const documentName = 'userProfileField'

export function* GET() {
  const { authProvider } = yield select(state => state.settings)

  try {
    const response = yield call(mapAuthProviders[authProvider].getUserProfileField)
    yield put({
      type: 'userProfileField/SET_STATE',
      payload: {
        loading: false,
        fields: response,
      },
    })
  } catch (e) {
    console.log(e)
  }
}

export function* ADD({ payload }) {
  const data = {
    ...payload,
  }

  yield call(rsFirebase.firestore.addDocument, documentName, data)
  notification.success({
    message: '등록 완료.',
    description: '회원 가입 항목이 등록되었습니다.',
    duration: 1,
  })
}

export function* DELETE({ payload }) {
  const { id } = payload
  yield call(rsFirebase.firestore.deleteDocument, `${documentName}/${id}`)

  notification.success({
    message: '삭제 완료.',
    description: '회원 가입 항목이 삭제되었습니다.',
    duration: 1,
  })
}

export function* UPDATE({ payload }) {
  const fieldId = payload.id
  const data = {
    title: payload.title,
    type: payload.type,
    key: payload.key,
    required: payload.required,
    display: payload.display,
    placeHolder: payload.placeHolder,
    showOnChat: payload.showOnChat,
    userType: payload.userType,
  }
  if (payload.options && payload.options.length > 0) {
    data.options = payload.options
  }
  console.log(data)
  yield call(rsFirebase.firestore.setDocument, `${documentName}/${fieldId}`, data, { merge: true })

  notification.success({
    message: '수정 완료.',
    description: '회원 가입 항목이 수정되었습니다.',
    duration: 1,
  })
}

export function* ORDER({ payload }) {
  const { authProvider: authProviderName } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProviderName].reorderList, payload, 'userProfileField')
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD, ADD),
    takeEvery(actions.GET, GET),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.ORDER, ORDER),
    GET(),
  ])
}
