/* eslint-disable no-unused-vars */
import { all, takeEvery, select, call } from 'redux-saga/effects'
import * as firebase from 'services/firebase'
// import moment from 'moment'
import _ from 'lodash'
import { rsFirebase, fireBase, fireStore } from '../../firebase'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    deleteDocument: firebase.deleteDocument,
    deleteCollection: firebase.deleteCollection,
  },
}

export function* SEND_MESSAGE({ payload }) {
  const now = fireBase.firestore.Timestamp.now()
  const messageId = `${now.seconds}-${now.nanoseconds}`
  const data = {
    id: messageId,
    sessionId: payload.session.id,
    message: payload.message,
    user: payload.user,
    deleted: false,
    highlight: false,
    createdTimestamp: now,
    createdAt: now.seconds,
  }
  const documentRef = `chat/${payload.session.id}/messages/${messageId}/`
  yield call(rsFirebase.firestore.setDocument, documentRef, data, { merge: true })
}

export function* TOGGLE_HIGHLIGHT({ payload }) {
  const highlighted = !payload.highlight
  const data = {
    highlight: highlighted,
  }
  const documentRef = `chat/${payload.sessionId}/messages/${payload.id}/`
  yield call(rsFirebase.firestore.setDocument, documentRef, data, { merge: true })
}

export function* GET_MESSAGES({ payload }) {
  const { session, schedule } = payload
  let collectionPath = ''
  let collectionRef = null
  // console.log(schedule)
  if (session && session.id) {
    // console.log('session id', session.id)
    if (session.id === 'ALL') {
      collectionRef = fireStore.collectionGroup('messages')
    } else {
      collectionPath = `chat/${session.id}/messages`
      collectionRef = fireStore.collection(collectionPath)
    }
  } else {
    return {
      type: actions.SET_STATE,
      payload: { messages: [] },
    }
  }

  const successActionCreator = data => {
    let messageList = []
    // data.docChanges().forEach(change => {
    //   console.log(change)
    // })
    data.docs.forEach(message => {
      messageList.push(message.data())
    })

    messageList = _.sortBy(messageList, 'createdAt', 'asc')
    // console.log('messageList', messageList)

    // console.log('successActionCreator')
    return {
      type: actions.SET_STATE,
      payload: { messages: messageList },
    }
  }

  yield call(
    rsFirebase.firestore.syncCollection,
    collectionRef.where('scheduleId', '==', schedule.id),
    {
      successActionCreator,
    },
  )
}

export function* DELETE_MESSAGE({ payload }) {
  const documentRef = `chat/${payload.sessionId}/messages/${payload.id}/`
  yield call(rsFirebase.firestore.deleteDocument, documentRef)
}
export function* TRUNCATE_ALL({ payload }) {
  const documentRef = `chat/${payload.id}`
  console.log('truncate chat', documentRef)

  // const result = yield call(rsFirebase.firestore.deleteDocument, documentRef)
  const { authProvider: authProviderName } = yield select(state => state.settings)

  const collectionPath = `chat/${payload.id}/messages`

  yield call(mapAuthProviders[authProviderName].deleteCollection, collectionPath, 200)

  yield call(mapAuthProviders[authProviderName].deleteDocument, 'chat', payload.id)
  // console.log(result)
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHAT_SEND_MESSAGE, SEND_MESSAGE),
    takeEvery(actions.TOGGLE_HIGHLIGHT, TOGGLE_HIGHLIGHT),
    takeEvery(actions.CHAT_GET_MESSAGES, GET_MESSAGES),
    takeEvery(actions.CHAT_DELETE_MESSAGE, DELETE_MESSAGE),
    takeEvery(actions.CHAT_TRUNCATE_ALL, TRUNCATE_ALL),
  ])
}
