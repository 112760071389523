const actions = {
  SET_STATE: 'speaker/SET_STATE',
  ADD: 'speaker/ADD',
  GET: 'speaker/GET',
  DELETE: 'speaker/DELETE',
  UPDATE: 'speaker/UPDATE',
  ORDER: 'speaker/ORDER',
}

export default actions
