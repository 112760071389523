// eslint-disable-next-line no-unused-vars
import { all, takeEvery, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
// import * as firebase from 'services/firebase'
import { rsFirebase } from '../../firebase'
import actions from './actions'

// eslint-disable-next-line require-yield
export function* ADD({ payload }) {
  const documentName = `pages/${payload.pageName}`

  const data = {
    ...payload.page,
  }

  const docRef = yield call(rsFirebase.firestore.addDocument, documentName, data)
  const idData = {
    id: docRef.id,
  }
  yield call(rsFirebase.firestore.setDocument, docRef, idData, { merge: true })
  notification.success({
    message: '등록 완료.',
    description: '페이지기 등록되었습니다.',
    duration: 1,
  })
}

export function* DELETE({ payload }) {
  const { pageName } = payload

  const documentName = `pages/${pageName}`

  yield call(rsFirebase.firestore.deleteDocument, documentName)

  notification.success({
    message: '삭제 완료.',
    description: '페이지가 삭제되었습니다.',
    duration: 1,
  })
}

export function* UPDATE({ payload }) {
  const data = {
    ...payload.page,
  }
  console.log(payload)

  const documentName = `pages/${payload.pageName}`
  console.log(documentName)

  yield call(rsFirebase.firestore.setDocument, documentName, data, { merge: true })

  notification.success({
    message: '수정 완료.',
    description: '페이지가 수정되었습니다.',
    duration: 1,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD, ADD),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.UPDATE, UPDATE),
  ])
}
