import { all, takeEvery, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
// import { history } from 'App'
// import * as firebase from 'services/firebase'
import * as firebase from 'services/firebase'
import { rsFirebase } from '../../firebase'
// import { USER, PATH } from 'constant'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    reorderList: firebase.reorderList,
    updateUser: firebase.updateUser,
  },
}

export function* DELETE_QUESTION({ payload }) {
  yield call(rsFirebase.firestore.deleteDocument, `examinationQuestions/${payload.id}`)

  notification.success({
    message: '삭제 완료.',
    description: '문제가 삭제되었습니다.',
    duration: 1,
  })
}

export function* ADD_QUESTION({ payload }) {
  const data = {
    ...payload,
    // order: 999999,
  }
  console.log(data)

  yield call(rsFirebase.firestore.addDocument, 'examinationQuestions', data)
  notification.success({
    message: '등록 완료.',
    description: '문제가 등록되었습니다.',
    duration: 1,
  })
}

export function* UPDATE_QUESTION({ payload }) {
  console.log(payload)

  const examId = payload.id
  const data = {
    title: payload.title,
    type: payload.type,
    answer: payload.answer,
    examId: payload.examId,
    indent: payload.indent || false,
    exampleVisible: payload.exampleVisible || false,
    example: payload.example || null,
  }
  if (payload.questions && payload.questions.length > 0) {
    data.questions = payload.questions
  }
  yield call(rsFirebase.firestore.setDocument, `examinationQuestions/${examId}`, data, {
    merge: true,
  })

  notification.success({
    message: '수정 완료.',
    description: '문제가 수정되었습니다.',
    duration: 1,
  })
}

export function* ORDER_QUESTION({ payload }) {
  const { authProvider: authProviderName } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProviderName].reorderList, payload, 'examinationQuestions')
}

export function* UPDATE({ payload }) {
  console.log(payload)
  const examId = payload.id
  const data = {
    title: payload.title,
    header: payload.header,
    footer: payload.footer,
    open: payload.open,
    passMark: payload.passMark,
    live: payload.live,
  }

  yield call(rsFirebase.firestore.setDocument, `exams/${examId}`, data, { merge: true })

  notification.success({
    message: '저장 완료.',
    description: '시험 정보가 저장되었습니다.',
    duration: 1,
  })
}

export function* DELETE({ payload }) {
  yield call(rsFirebase.firestore.deleteDocument, `exams/${payload.id}`)

  notification.success({
    message: '삭제 완료.',
    description: '시험이 삭제되었습니다.',
    duration: 1,
  })
}

export function* TOGGLE({ payload }) {
  const examId = payload.id
  const data = {
    open: !payload.open,
  }

  yield call(rsFirebase.firestore.setDocument, `exams/${examId}`, data, { merge: true })

  notification.success({
    message: '수정 완료.',
    description: '시험이 수정되었습니다.',
    duration: 1,
  })
}

// export function* TOGGLE_LIVE({ payload }) {
//   const { authProvider: authProviderName } = yield select(state => state.settings)
//   yield call(
//     mapAuthProviders[authProviderName].toggl,
//     payload.examId,
//     payload.examinationQuestion,
//   )
// }

export function* DELETE_REPORT({ payload }) {
  const { id } = payload
  yield call(rsFirebase.firestore.deleteDocument, `examReports/${id}`)

  const { authProvider: authProviderName } = yield select(state => state.settings)
  const userPayload = {
    id: payload.user.id,
    values: {
      surveyComplete: false,
    },
  }
  yield call(mapAuthProviders[authProviderName].updateUser, userPayload)

  notification.success({
    message: '삭제 완료.',
    description: '시험결과가 삭제되었습니다.',
    duration: 1,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD_QUESTION, ADD_QUESTION),
    takeEvery(actions.DELETE_QUESTION, DELETE_QUESTION),
    takeEvery(actions.UPDATE_QUESTION, UPDATE_QUESTION),
    takeEvery(actions.ORDER_QUESTION, ORDER_QUESTION),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
    // takeEvery(actions.TOGGLE_LIVE, TOGGLE_LIVE),
    takeEvery(actions.DELETE_REPORT, DELETE_REPORT),
    takeEvery(actions.TOGGLE, TOGGLE),
  ])
}
