// eslint-disable-next-line
import React, { lazy, Suspense } from 'react'

// import firebaseConfig from './firebase/'
import { ConnectedRouter } from 'connected-react-router'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import PATH from 'path.config'

import { CSSTransition, SwitchTransition } from 'react-transition-group'

import Layout from './layouts'

const routes = [
  // Dashboards
  {
    path: PATH.ADMIN.HOME,
    key: PATH.ADMIN.HOME,
    Component: lazy(() => import('./pages/Admin/Home')),
    exact: true,
  },
  {
    path: PATH.ADMIN.DASHBOARD,
    key: PATH.ADMIN.DASHBOARD,
    Component: lazy(() => import('./pages/Admin/Dashboard')),
    exact: true,
  },
  {
    path: PATH.ADMIN.SITE_SETTINGS,
    key: PATH.ADMIN.SITE_SETTINGS,
    Component: lazy(() => import('./pages/Admin/Pages/Site')),
    exact: true,
  },
  {
    path: PATH.ADMIN.SITE_USER_AUTH,
    key: PATH.ADMIN.SITE_USER_AUTH,
    Component: lazy(() => import('./pages/Admin/Project/User')),
    exact: true,
  },
  {
    path: PATH.ADMIN.SITE_WELCOME_MAIL,
    key: PATH.ADMIN.SITE_WELCOME_MAIL,
    Component: lazy(() => import('./pages/Admin/Project/WelcomeMail')),
    exact: true,
  },

  {
    path: PATH.ADMIN.CONTROLBOX,
    key: PATH.ADMIN.CONTROLBOX,
    Component: lazy(() => import('./pages/Admin/ControlBox')),
    exact: true,
  },

  {
    path: PATH.ADMIN.SCHEDULE,
    key: PATH.ADMIN.SCHEDULE,
    Component: lazy(() => import('./pages/Admin/Schedule')),
    exact: true,
  },
  {
    path: PATH.ADMIN.LIVE,
    key: PATH.ADMIN.LIVE,
    Component: lazy(() => import('./pages/Admin/Live/List')),
    exact: true,
  },
  {
    path: PATH.ADMIN.VOD,
    key: PATH.ADMIN.VOD,
    Component: lazy(() => import('./pages/Admin/VOD/List')),
    exact: true,
  },

  {
    path: PATH.ADMIN.LOG_REPORT,
    key: PATH.ADMIN.LOG_REPORT,
    Component: lazy(() => import('./pages/Admin/LogReport')),
    exact: true,
  },
  {
    path: PATH.ADMIN.DIRECTOR,
    key: PATH.ADMIN.DIRECTOR,
    Component: lazy(() => import('./pages/Admin/Director')),
    exact: true,
  },
  {
    path: PATH.ADMIN.CHAT,
    key: PATH.ADMIN.CHAT,
    Component: lazy(() => import('./pages/Admin/Chat')),
    exact: true,
  },
  {
    path: PATH.ADMIN.LIVE_SURVEY,
    key: PATH.ADMIN.LIVE_SURVEY,
    Component: lazy(() => import('./pages/Admin/Survey/Live')),
    exact: true,
  },
  {
    path: PATH.ADMIN.CHANNEL,
    key: PATH.ADMIN.CHANNEL,
    Component: lazy(() => import('./pages/Admin/Channel')),
    exact: true,
  },

  {
    path: PATH.ADMIN.SURVEY_LIST,
    key: PATH.ADMIN.SURVEY_LIST,
    Component: lazy(() => import('./pages/Admin/Survey/List')),
    exact: true,
  },
  {
    path: `${PATH.ADMIN.SURVEY_ADD}/:id?`,
    key: PATH.ADMIN.SURVEY_ADD,
    Component: lazy(() => import('./pages/Admin/Survey/Form')),
    exact: true,
  },
  {
    path: `${PATH.ADMIN.SURVEY_EDIT}/:id`,
    key: PATH.ADMIN.SURVEY_EDIT,
    Component: lazy(() => import('./pages/Admin/Survey/Form')),
    exact: false,
  },
  {
    path: `${PATH.ADMIN.SURVEY_REPORT}/:id`,
    key: PATH.ADMIN.SURVEY_REPORT,
    Component: lazy(() => import('./pages/Admin/Survey/Report')),
    exact: true,
  },

  {
    path: PATH.ADMIN.EXAM_LIST,
    key: PATH.ADMIN.EXAM_LIST,
    Component: lazy(() => import('./pages/Admin/Exam/List')),
    exact: true,
  },
  {
    path: `${PATH.ADMIN.EXAM_ADD}/:id?`,
    key: PATH.ADMIN.EXAM_ADD,
    Component: lazy(() => import('./pages/Admin/Exam/Form')),
    exact: true,
  },
  {
    path: `${PATH.ADMIN.EXAM_EDIT}/:id`,
    key: PATH.ADMIN.EXAM_EDIT,
    Component: lazy(() => import('./pages/Admin/Exam/Form')),
    exact: false,
  },
  {
    path: `${PATH.ADMIN.EXAM_REPORT}/:id`,
    key: PATH.ADMIN.EXAM_REPORT,
    Component: lazy(() => import('./pages/Admin/Exam/Report')),
    exact: true,
  },
  {
    path: PATH.ADMIN.USERS,
    key: PATH.ADMIN.USERS,
    Component: lazy(() => import('./pages/Admin/Users/User/List')),
    exact: true,
  },
  {
    path: PATH.ADMIN.PROFILE,
    key: PATH.ADMIN.PROFILE,
    Component: lazy(() => import('./pages/Admin/Users/User/Profile')),
    exact: true,
  },
  {
    path: PATH.ADMIN.TICKET,
    key: PATH.ADMIN.TICKET,
    Component: lazy(() => import('./pages/Admin/Users/Ticket')),
    exact: true,
  },
  {
    path: PATH.ADMIN.AGREEMENTS,
    key: PATH.ADMIN.AGREEMENTS,
    Component: lazy(() => import('./pages/Admin/Users/Agreements')),
    exact: true,
  },
  {
    path: PATH.ADMIN.USER_BATCH_REGISTER,
    key: PATH.ADMIN.USER_BATCH_REGISTER,
    Component: lazy(() => import('./pages/Admin/Users/BatchRegister')),
    exact: true,
  },
  {
    path: PATH.ADMIN.WELCOME,
    key: PATH.ADMIN.WELCOME,
    Component: lazy(() => import('./pages/Admin/Pages/Welcome')),
    exact: true,
  },
  {
    path: PATH.ADMIN.ABOUT,
    key: PATH.ADMIN.ABOUT,
    Component: lazy(() => import('./pages/Admin/Pages/About')),
    exact: true,
  },
  {
    path: PATH.ADMIN.SPONSOR,
    key: PATH.ADMIN.SPONSOR,
    Component: lazy(() => import('./pages/Admin/Pages/Sponsor')),
    exact: true,
  },
  {
    path: PATH.ADMIN.SEGMENTS,
    key: PATH.ADMIN.SEGMENTS,
    Component: lazy(() => import('./pages/Admin/Pages/Segments')),
    exact: false,
  },
  // {
  //   path: PATH.ADMIN.GRADE_GUIDE,
  //   key: PATH.ADMIN.GRADE_GUIDE,
  //   Component: lazy(() => import('./pages/Admin/Pages/GradeGuide')),
  //   exact: true,
  // },
  // {
  //   path: PATH.ADMIN.REGISTER_INFO,
  //   key: PATH.ADMIN.REGISTER_INFO,
  //   Component: lazy(() => import('./pages/Admin/Pages/RegisterInfo')),
  //   exact: true,
  // },
  {
    path: PATH.ADMIN.SPEAKER,
    key: PATH.ADMIN.SPEAKER,
    Component: lazy(() => import('./pages/Admin/Pages/Speaker')),
    exact: true,
  },
  {
    path: PATH.ADMIN.MENU,
    key: PATH.ADMIN.MENU,
    Component: lazy(() => import('./pages/Admin/Pages/Menu')),
    exact: true,
  },
  {
    path: `${PATH.ADMIN.MENU}/:id?`,
    key: PATH.ADMIN.MENU_CHILD,
    Component: lazy(() => import('./pages/Admin/Pages/Menu/Child')),
    exact: true,
  },
  {
    path: PATH.ADMIN.UTIL_EMAIL_VALIDATOR,
    key: PATH.ADMIN.UTIL_EMAIL_VALIDATOR,
    Component: lazy(() => import('./pages/Admin/Util/EmailValidator')),
    exact: true,
  },
  {
    path: PATH.AUTH.LOGIN,
    key: PATH.AUTH.LOGIN,
    Component: lazy(() => import('./components/Auth/Login')),
    exact: true,
  },
  {
    path: PATH.AUTH.LOGOUT,
    key: PATH.AUTH.LOGOUT,
    Component: lazy(() => import('./components/Auth/Logout')),
    exact: true,
  },
  {
    path: PATH.SYSTEM_404,
    key: PATH.SYSTEM_404,
    Component: lazy(() => import('./pages/404')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
  // routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state

            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/admin" />} />
                    {routes.map(({ path, Component, exact, key }) => {
                      // console.log(key)
                      return (
                        <Route
                          path={path}
                          key={key}
                          exact={exact}
                          render={() => {
                            return (
                              <div className={routerAnimation}>
                                <Suspense fallback={null}>
                                  <Component />
                                </Suspense>
                              </div>
                            )
                          }}
                        />
                      )
                    })}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
