const actions = {
  SET_STATE: 'pageSegments/SET_STATE',
  ADD: 'pageSegments/ADD',
  GET: 'pageSegments/GET',
  DELETE: 'pageSegments/DELETE',
  UPDATE: 'pageSegments/UPDATE',
  ORDER: 'pageSegments/ORDER',
}

export default actions
