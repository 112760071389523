const actions = {
  SET_STATE: 'userType/SET_STATE',
  ADD: 'userType/ADD',
  GET: 'userType/GET',
  DELETE: 'userType/DELETE',
  UPDATE: 'userType/UPDATE',
  ORDER: 'userType/ORDER',
}

export default actions
