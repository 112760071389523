const actions = {
  ADD_QUESTION: 'exam/ADD_QUESTION',
  UPDATE_QUESTION: 'exam/UPDATE_QUESTION',
  DELETE_QUESTION: 'exam/DELETE_QUESTION',
  ORDER_QUESTION: 'exam/ORDER_QUESTION',
  TOGGLE_LIVE_QUESTION: 'exam/TOGGLE_LIVE_QUESTION',

  DELETE_REPORT: 'exam/DELETE_REPORT',
  ADD: 'exam/ADD',
  UPDATE: 'exam/UPDATE',
  DELETE: 'exam/DELETE',
  TOGGLE: 'exam/TOGGLE',
}

export default actions
