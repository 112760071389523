import actions from './actions'

const initialState = {
  id: null,
  order: 999999,
  title: '',
  type: '',
  questions: [],
  answer: 0,
  indent: false,
  loading: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
