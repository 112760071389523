const actions = {
  ADD: 'publicMenu/ADD',
  UPDATE: 'publicMenu/UPDATE',
  DELETE: 'publicMenu/DELETE',
  TOGGLE: 'publicMenu/TOGGLE',
  TOGGLE_WELCOME: 'publicMenu/TOGGLE_WELCOME',
  TRUNCATE: 'publicMenu/TRUNCATE',
  ORDER: 'publicMenu/ORDER',
  WELCOME_ORDER: 'publicMenu/WELCOME_ORDER',
}

export default actions
