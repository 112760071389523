// eslint-disable-next-line no-unused-vars
import { all, takeEvery, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import * as firebase from 'services/firebase'
import { rsFirebase } from '../../firebase'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    reorderList: firebase.reorderList,
  },
}

const documentName = 'speakers'

export function* ADD({ payload }) {
  console.log(payload)
  const data = {
    ...payload,
  }

  const docRef = yield call(rsFirebase.firestore.addDocument, documentName, data)
  const idData = {
    id: docRef.id,
  }
  yield call(rsFirebase.firestore.setDocument, docRef, idData, { merge: true })
  notification.success({
    message: '등록 완료.',
    description: '연자가 등록되었습니다.',
    duration: 1,
  })
}

export function* DELETE({ payload }) {
  const { id } = payload
  yield call(rsFirebase.firestore.deleteDocument, `${documentName}/${id}`)

  notification.success({
    message: '삭제 완료.',
    description: '연자가 삭제되었습니다.',
    duration: 1,
  })
}

export function* UPDATE({ payload }) {
  console.log(payload)

  const fieldId = payload.id
  const data = {
    id: payload.id,
    ...payload,
  }

  yield call(rsFirebase.firestore.setDocument, `${documentName}/${fieldId}`, data, { merge: true })

  notification.success({
    message: '수정 완료.',
    description: '연자가 수정되었습니다.',
    duration: 1,
  })
}

export function* ORDER({ payload }) {
  const { authProvider: authProviderName } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProviderName].reorderList, payload, 'speakers')
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD, ADD),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.ORDER, ORDER),
  ])
}
