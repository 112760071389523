const actions = {
  SET_STATE: 'sponsor/SET_STATE',
  ADD: 'sponsor/ADD',
  GET: 'sponsor/GET',
  DELETE: 'sponsor/DELETE',
  UPDATE: 'sponsor/UPDATE',
  ORDER: 'sponsor/ORDER',
}

export default actions
