import { all, takeEvery, call } from 'redux-saga/effects'
import { notification } from 'antd'
// import { history } from 'App'
// import * as firebase from 'services/firebase'
// import * as firebase from 'services/firebase'
// import moment from 'moment'
// import { deactivate, currentSession } from 'services/firebase'
// import _ from 'lsodash'
import * as firebase from 'services/firebase'
import { rsFirebase } from '../../firebase'
// import { USER, PATH } from 'constant'
import actions from './actions'

const documentName = 'project'
export function* SET_SETTINGS({ payload }) {
  const fieldId = 'settings'
  const data = {
    ...payload,
  }
  yield call(rsFirebase.firestore.setDocument, `${documentName}/${fieldId}`, data, { merge: true })
  notification.success({
    message: '저장 완료.',
    description: '페이지 정보가 저장되었습니다.',
    duration: 1,
  })
}

export function* GET_SETTINGS() {
  const fieldId = 'settings'

  const successActionCreator = doc => {
    // console.log(doc)
    let settings = {}
    if (doc && doc.exists) {
      settings = doc.data()
    }

    // console.log('successActionCreator')
    return {
      type: actions.SET_STATE,
      payload: { settings: settings },
    }
  }

  yield call(rsFirebase.firestore.syncDocument, `${documentName}/${fieldId}`, {
    successActionCreator,
  })
}

export function* CALL_FUNCTION({ payload }) {
  const success = yield call(firebase.functionCall, payload)
  console.log(success)
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_SETTINGS, SET_SETTINGS),
    takeEvery(actions.GET_SETTINGS, GET_SETTINGS),
    takeEvery(actions.CALL_FUNCTION, CALL_FUNCTION),
    GET_SETTINGS(),
  ])
}
