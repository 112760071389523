import { all, takeEvery, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
// import { history } from 'App'
// import * as firebase from 'services/firebase'
import * as firebase from 'services/firebase'
import { rsFirebase } from '../../firebase'
// import { USER, PATH } from 'constant'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    reorderList: firebase.reorderList,
    reorderListTarget: firebase.reorderListTarget,
  },
}

export function* ADD({ payload }) {
  const data = {
    ...payload,
    // order: 999999,
  }
  console.log(data)

  yield call(rsFirebase.firestore.addDocument, 'menus', data)
  notification.success({
    message: '등록 완료.',
    description: '메뉴가 등록되었습니다.',
    duration: 1,
  })
}

export function* UPDATE({ payload }) {
  console.log(payload)
  const menuId = payload.id
  const data = {
    ...payload,
    // order: 999999,
  }

  yield call(rsFirebase.firestore.setDocument, `menus/${menuId}`, data, { merge: true })

  notification.success({
    message: '저장 완료.',
    description: '메뉴가 저장되었습니다.',
    duration: 1,
  })
}

export function* DELETE({ payload }) {
  yield call(rsFirebase.firestore.deleteDocument, `menus/${payload.id}`)

  notification.success({
    message: '삭제 완료.',
    description: '메뉴가 삭제되었습니다.',
    duration: 1,
  })
}

export function* ORDER({ payload }) {
  const { authProvider: authProviderName } = yield select(state => state.settings)

  yield call(mapAuthProviders[authProviderName].reorderList, payload, 'menus')
}
export function* WELCOME_ORDER({ payload }) {
  const { authProvider: authProviderName } = yield select(state => state.settings)

  yield call(
    mapAuthProviders[authProviderName].reorderListTarget,
    payload,
    'menus',
    'welcome_order',
  )
}

export function* TOGGLE({ payload }) {
  const menuId = payload.id
  const data = {
    opened: payload.opened,
  }

  yield call(rsFirebase.firestore.setDocument, `menus/${menuId}`, data, { merge: true })
}

export function* TOGGLE_WELCOME({ payload }) {
  const menuId = payload.id
  const data = {
    welcome: payload.welcome,
  }

  yield call(rsFirebase.firestore.setDocument, `menus/${menuId}`, data, { merge: true })
}

export function* TRUNCATE({ payload }) {
  const { menus } = payload
  yield all(menus.map(menu => call(rsFirebase.firestore.deleteDocument, `menus/${menu.id}`)))
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD, ADD),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.TOGGLE, TOGGLE),
    takeEvery(actions.TOGGLE_WELCOME, TOGGLE_WELCOME),
    takeEvery(actions.TRUNCATE, TRUNCATE),
    takeEvery(actions.ORDER, ORDER),
    takeEvery(actions.WELCOME_ORDER, WELCOME_ORDER),
  ])
}
